<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="white" class="error--text" fab v-bind="attrs" v-on="on" @click="$emit('click')">
        <v-icon v-text="'call_end'" />
      </v-btn>
    </template>
    <span v-text="$t('streaming.buttons.exit')" />
  </v-tooltip>
</template>

<script>
export default {
  name: 'ExitButton',
}
</script>
